<template>
  <div class="chipchange_panel">
    <div class="btn btn-money-int" @click="change2casinocash">
      <div class="txt">
        <span class="t1">칩전환</span>
        <span class="t2">CHIP IN</span>
      </div>
      <div class="img"><img src="../../../assets/images/casino/btn/btn1-3.png"></div>
    </div>
    <div class="btn btn-money-out" @click="change2sportsmoney">
      <div class="txt">
        <span class="t1">머니전환</span>
        <span class="t2">CHIP OUT</span>
      </div>
      <div class="img"><img src="../../../assets/images/casino/btn/btn2-3.png"></div>
    </div>
  </div>
</template>


<script>


     import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
     import {casinoMixin, postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import leisureConst from "@/common/leisureConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
    export default {
      name: "MoneyChipChangeComp",
      mixins: [postionMixin,casinoMixin],
      components: {
        SubTitle

      },
      data() {
        return {
          position: "카지노",
        }
      },
      methods: {

      },
      created() {
      },
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontcasinoslot.css");
</style>